<template>
  <v-container class="mt-3 px-sm-10 px-3 segments-accordion elevation-0" fluid>
    <div class="nav-link d-flex mb-6 mt-10" @click="returnToCampaign">
      <div class="pr-2">
        &#60;
      </div>
      <div>{{ backTitle }}</div>
    </div>
    <PageHeader header-text="Schedule Campaign">
      <template #subheader>
        Confirm your campaign details and publish now
      </template>
    </PageHeader>

    <v-row class="mt-15">
      <v-col cols="12" sm="4">
        <div class="field__header mb-2">
          Campaign Name
        </div>
        <div class="field__subheader">
          {{ campaign_config.name }}
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <div class="field__header mb-2">
          Email Type
        </div>
        <div class="field__subheader">
          {{ campaign_config.type }}
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <div class="field__header mb-2">
          Estimate Total Recipients
        </div>
        <div class="field__number">
          {{ estimate_total_recepients }}
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-2">
      <v-col cols="12" sm="4">
        <div class="field__header mb-3">
          Segments
        </div>
        <div v-for="(segment, i) in segments_info" :key="i" class="segments-list">
          <div class="d-flex flex-nowrap align-top pb-3">
            <div v-if="segments_info.length > 1" class="field__number pr-2">
              {{ (segment.contact_count * 100 / estimate_total_recepients).toFixed(1) }}%
            </div>
            <div class="field__subheader">
              {{ `${segment.name} (${segment.contact_count} estimated)` }}
            </div>
          </div>
        </div>
      </v-col>

      <v-col
        v-if="(campaign_config.split_tests && campaign_config.split_tests.length) || (campaign_config.regular_tests && campaign_config.regular_tests.length)"
        cols="12"
        sm="4"
      >
        <div class="field__header mb-3">
          Subjects
        </div>
        <div v-if="campaign_config.split_tests && campaign_config.split_tests.length" class="segments-list">
          <div v-for="(value, index) in campaign_config.split_tests" :key="index" class="pb-2">
            <div class="creative-heading pb-2">
              {{ value.name }}
            </div>
            <div v-for="(inner_value, inner_key) in value" :key="inner_key" class="pb-1 ml-2">
              <div v-if="inner_key && inner_value && inner_value.subject && inner_value.previewText">
                <span class="field__subheader pr-2">{{ inner_key }}:</span>
                <span class="field__subheader--small">
                  {{ inner_value.subject }} - {{ inner_value.previewText }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="campaign_config.regular_tests && campaign_config.regular_tests.length" class="segments-list">
          <div v-for="(value, index) in campaign_config.regular_tests" :key="index" class="pb-3">
            <div v-if="value && value.name && value.subject && value.previewText" class="creative-heading pb-2">
              {{ value.name }}
            </div>
            <div v-if="value && value.subject && value.previewText" class="field__subheader--small">
              {{ value.subject }} - {{ value.previewText }}
            </div>
          </div>
        </div>
      </v-col>

      <v-col
        v-if="(campaign_config.split_tests && campaign_config.split_tests.length) || (campaign_config.regular_tests && campaign_config.regular_tests.length)"
        cols="12"
        sm="4"
      >
        <div class="field__header mb-3">
          Creatives
        </div>
        <div class="d-flex flex-wrap">
          <div v-if="campaign_config.split_tests && campaign_config.split_tests.length" class="d-flex flex-wrap">
            <div
              v-for="(item, key) in campaign_config.split_tests"
              :key="key"
              class="mr-5 mb-3"
            >
              <div class="image-container d-flex flex-column align-center">
                <v-icon size="27" color="white" class="zoom-in-icon">
                  mdi-magnify-plus-outline
                </v-icon>
                <img
                  :src="get_creative_image_src(item)"
                  class="image-to-show"
                  alt=""
                >
              </div>
              <div class="field__number pt-1 image-capture">
                {{ item.name }}
              </div>
            </div>
          </div>
          <div v-if="campaign_config.regular_tests && campaign_config.regular_tests.length" class="d-flex flex-wrap">
            <div
              v-for="(item, key) in campaign_config.regular_tests"
              :key="key"
              class="mr-5 mb-3"
            >
              <div class="image-container d-flex flex-column align-center">
                <v-icon size="27" color="white" class="zoom-in-icon">
                  mdi-magnify-plus-outline
                </v-icon>
                <img
                  :src="get_creative_image_src(item)"
                  class="image-to-show"
                  alt=""
                >
              </div>
              <div class="field__number pt-1 image-capture">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-divider class="mt-4 mb-6" />

    <v-row class="py-0">
      <v-col cols="12" sm="4" class="py-0">
        <div class="field__header mb-3">
          Delivery Date
        </div>
        <CustomDateAndTimePicker
          v-if="!readOnly"
          :time="deliveryTime"
          :date="deliveryDate"
          :allowed_minutes="[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]"
          placeholder="Pick the delivery date"
          @date-input="(newDate) => (deliveryDate = newDate)"
          @time-input="(newTime) => (deliveryTime = newTime)"
        />
        <span v-else class="field__subheader">{{ campaign_raw.displayStartDate }}</span>
      </v-col>
      <v-col cols="12" sm="4" class="py-0">
        <TimezoneDropdown
          v-if="!readOnly"
          v-model="timeZone"
          header-class="field__header mb-7"
        />
        <span v-else class="field__subheader">{{ timeZone }}</span>
      </v-col>
    </v-row>

    <v-row class="my-10">
      <v-col cols="12" class="d-flex justify-end align-center flex-wrap">
        <div class="campaign-text mr-8 py-2 mb-4">
          Once an automation campaign has been created, it can no longer be
          edited
        </div>
        <v-btn
          v-if="!readOnly"
          class="custom-button custom-button--gray px-11 mb-4"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="save_draft_campaign()"
        >
          Save Draft
        </v-btn>
        <v-btn
          v-if="!readOnly"
          class="custom-button custom-button--blue px-9 ml-sm-5 mb-4"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="save_run_campaign()"
        >
          Schedule Now
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import CustomDateAndTimePicker from "@/sharedComponents/CustomDateAndTimePicker";
import TimezoneDropdown from "@/sharedComponents/TimezoneDropdown";
import { isEmpty } from "lodash";

export default {
  name: "ScheduleCampaignView",
  metaInfo: {
    title: 'Schedule Campaign'
  },
  components: {
    TimezoneDropdown,
    PageHeader,
    CustomDateAndTimePicker,
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('automation/setNodes', {});
    next();
  },
  props: {
    id: {
      default: () => false
    }
  },
  data() {
    return {
      campaign_config: {
        name: '',
        type: '',
      },
      campaign_raw: {},
      campaignType: '',
      available_segments: [],
      available_creatives: [],
      segments_info: [],
      campaign: {},
      creatives: [{ id: "A" }, { id: "B" }, { id: "C" }],
      timeZone: null,
      deliveryDate: null,
      deliveryTime: null,
    };
  },
  computed: {
    transitionsForMainLayer() {
      return this.$store.getters['automation/getTransitionsForMainLayer'];
    },
    estimate_total_recepients() {
      if (!this.segments_info.length) {
        return 0
      }
      let sum = 0
      this.segments_info.forEach(item => {
        sum += item.contact_count
      })
      return sum
    },
    backTitle() {
      return ['preparing','live','completed','canceled'].includes(this.campaign_raw.status) ? 'Return to Campaigns' : 'Return to Campaign Creation'
    },
    readOnly() {
      return ['preparing','live','completed','canceled'].includes(this.campaign_raw.status)
    }
  },
  async created() {
    await this.load_segments();
    await this.load_creatives();
    if (this.id){
      await this.load_campaign(this.id)
    }
  },
  methods: {
    async load_segments(){
      let segments = (await this.$rest.segment.get_collection({
        ignorePagination: true
      })).data;

      this.available_segments = segments.items.map(segment => ({
        ...segment,
        name: segment.isArchived ? segment.name + " [Archived]" : segment.name
      }));
    },
    async load_campaign(id) {
      const campaign = (await this.$rest.campaign.get_resource(id)).data;
      this.campaign_raw = {...campaign};
      this.campaignType = campaign.type;
      const segment_nodes = Object.values(campaign.draftConfiguration.nodes)
        .filter(i => i.nodeType === 'Segment' && !!i?.config?.name);

      segment_nodes.forEach(node => {
        this.segments_info.push({
          name: node?.config?.name,
          contact_count: this.get_segment_contact_count(node.config.segmentId)
        })
      });

      if (campaign.startDate) {
        this.deliveryDate = campaign.startDate.split('T')[0];
        this.deliveryTime = campaign.startDate.split('T')[1].split('-')[0];
      }
      this.timeZone = campaign.timezoneOffset;

      // same variables of types
      this.campaign_config.name = campaign.name;

      const regular_email_nodes = Object.values(campaign.draftConfiguration.nodes)
          .filter(i => i.nodeType === 'SendEmail');
      const split_email_nodes = Object.values(campaign.draftConfiguration.nodes)
          .filter(i => i.nodeType === 'SendSplitTestEmail');

      this.campaign_config.type = campaign.type === 'simple'
        ? 'Simple'
        : (
          campaign.type === 'split-test'
            ? 'Split Test'
            : 'Automation'
        );

      if (regular_email_nodes.length) {
        this.campaign_config.regular_tests = [];
        regular_email_nodes.forEach(item => {
          this.campaign_config.regular_tests.push({
            name: item.name || item.config.label || 'Send Email',
            subject: item.config.subject,
            previewText: item.config.previewText,
            creativeId: item.config.creativeId
          });
        });
      }

      if (split_email_nodes.length) {
        this.campaign_config.split_tests = [];
        split_email_nodes.forEach(item => {
          this.campaign_config.split_tests.push({
            name: item.name || item.config.label || 'Split Test Email',
            ...item.config.tests,
            creativeId: item.config.creative || item.config.creativeId
          });
        });
      }
    },
    async load_creatives(){
      const creatives = (await this.$rest.creative.email.get_collection({
        isArchived: false,
        ignorePagination: false,
        htmlContentExist: true,
        sort: ['createdAt:desc'],
      })).data.items

      this.available_creatives = creatives.map(creative => ({
        ...creative,
        name: creative.isArchived ? creative.name + " [Archived]" : creative.name
      }));
    },
    alertNotConnect() {
      this.$store.commit("snackbar/showMessage", {
        content:
          "You have items in the campaign that are not connected to anything. Please review again",
        color: "error",
      });
    },
    async save_run_campaign(){
      if (isEmpty(this.transitionsForMainLayer)) {
        this.alertNotConnect();
        return;
      }
      let nodesHasConnected = [];
      for (const key in this.transitionsForMainLayer) {
        nodesHasConnected.push(this.transitionsForMainLayer[key].startNode.nuid);
        nodesHasConnected.push(this.transitionsForMainLayer[key].targetNode.nuid);
      }
      nodesHasConnected = [...new Set(nodesHasConnected)];
      if (
        Object.keys(this.$store.getters["automation/getNodes"]).length !==
        nodesHasConnected.length
      ) {
        this.alertNotConnect();
        return;
      }

      let startDate = null;

      if (this.deliveryDate && this.deliveryTime) {
         startDate = `${this.deliveryDate} ${this.deliveryTime}`
      }
      await this.$rest.campaign.put_resource(this.id, {
        ...this.campaign_raw,
        startDate: startDate,
        status: 'scheduled',
        timezoneOffset: this.timeZone
      })
      await this.$router.push({ name: 'CampaignsSearch' });
    },
    async save_draft_campaign(){
      let startDate = null;

      if (this.deliveryDate && this.deliveryTime) {
        startDate = `${this.deliveryDate} ${this.deliveryTime}`
      }
      await this.$rest.campaign.put_resource(this.id, {
        ...this.campaign_raw,
        startDate: startDate,
        timezoneOffset: this.timeZone
      })
      await this.$router.push({ name: 'CampaignsSearch' });
    },
    get_creative_image_src(creativeSource) {
      const creative_id = creativeSource.creativeId ?? creativeSource?.A?.creativeId;

      let creative = this.available_creatives.find(item => {
        return item.id === creative_id
      })
      if (creative && creative.id) {
        return creative.imageFileUrls.medium
      }
      return ""
    },
    get_segment_contact_count(id) {
      const segment = this.available_segments.find(item => item.id === id)

      return segment.contactCount
    },
    returnToCampaign() {
      if(!this.readOnly){
        if (this.campaignType === 'automation') {
          this.$router.push({
            name: 'AutomatedCampaign',
            params: { id: this.campaign_raw.id }
          })
        } else {
          this.$router.push({
            name: 'SimpleCampaign',
            params: { id: this.campaign_raw.id }
          })
        }
      }else {
        this.$router.push({ name: 'CampaignsSearch' })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-link {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2b84eb;
  cursor: pointer;
}
.image-container {
  cursor: pointer;

  &:hover > .zoom-in-icon {
    display: block;
  }
  &:hover > .image-to-show {
    opacity: 0.7;
  }

  .image-to-show {
    max-height: 95px;
    max-width: 95px;
  }
  .zoom-in-icon {
    position: absolute;
    z-index: 2;
    margin-top: 36px;
    display: none;
  }
}
.image-capture {
  text-align: center;
  max-width: 95px;
}
.campaign-text {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: normal;
  text-align: right;
  color: #66788e;
}
.custom-text-input.timezone {
  .field__subheader {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #241c15 !important;
    margin-bottom: 0 !important;
  }

  ::v-deep .custom-dropdown {
    margin-top: 5px !important;
  }
}

.creative-heading {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #66788e;
}
</style>
